<template>
	<InertiaLink v-if="inertia" :href="href" :aria-label="label" :class="classes">
		<slot />
	</InertiaLink>
	<a v-else :href="href" :aria-label="label" :class="classes">
		<slot />
	</a>
</template>

<script setup lang="ts">
import { Link as InertiaLink } from '@inertiajs/vue3';

defineOptions({
	inheritAttrs: true,
});

defineProps({
	href: {
		type: String,
		required: true,
	},
	label: {
		type: String,
		default: '',
	},
	inertia: {
		type: Boolean,
		default: false,
	},
});

const classes = 'underline text-sm text-base-content/50 hover:text-base-content/90';
</script>
